export default {
  translation: {
    locale: 'fr-FR',
    'shared.french': 'Français',
    'app.name': 'Espace Partenaire Hellio',
    'auth.hero': 'Bienvenue sur votre\nEspace Partenaire',
  },
  global: {
    password: 'Mot de passe',
    email: 'E-mail',
    logout: 'Déconnexion',
    cancel: 'Annuler',
    required: 'Ce champ est obligatoire',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
    label: '{{label}} :',
    previous: 'Précédent',
    next: 'Suivant',
    yes: 'Oui',
    no: 'Non',
    date: 'Date',
    mr: 'Monsieur',
    mrs: 'Madame',
    validate: 'Valider',
    phone: 'Téléphone',
    number_not_allowed: 'Les nombres ne sont pas autorisés',
    email_invalid: 'Veuillez saisir un format mail valide',
    phone_invalid: 'Veuillez saisir un format numéro de téléphone valide',
    postal_code_invalid: 'Veuillez saisir un format code postal valide',
    export_date: "Date d'export : {{date}}",
    number_invalid: 'Veuillez saisir un nombre.',
    integer_invalid: 'Veuillez saisir un nombre entier.',
    enter_number: 'Saisissez un nombre…',
    enter_integer: 'Saisissez un nombre entier…',
    enter_number_interval: 'Saisissez un nombre entre {{min}} et {{max}}…',
    enter_integer_interval:
      'Saisissez un nombre entier entre {{min}} et {{max}}…',
  },
  filesize: {
    symbols: {
      iec: {
        bytes: {
          B: 'o',
          KiB: 'Kio',
          MiB: 'Mio',
          GiB: 'Gio',
          TiB: 'Tio',
          PiB: 'Pio',
          EiB: 'Eio',
          ZiB: 'Zio',
          YiB: 'Yio',
        },
      },
      jedec: {
        bytes: {
          B: 'o',
          KB: 'Ko',
          MB: 'Mo',
          GB: 'Go',
          TB: 'To',
          PB: 'Po',
          EB: 'Eo',
          ZB: 'Zo',
          YB: 'Yo',
        },
      },
    },
  },
  statuts_dossier: {
    EtudeEnCours: 'Étude en cours',
    OffreEmise: 'Offre émise',
    ChantierEnCours: 'Chantier en cours',
    DocumentsEnAttente: 'Documents en attente',
    Recu: 'Reçu',
    EnAttenteDeControle: 'En attente bureau de contrôle',
    Incomplet: 'Incomplet',
    Valide: 'Validé',
    PrimeProPayee: 'Prime Pro payée',
    PrimeBeneficiairePayee: 'Prime Bénéficiaire payée',
    PrimeGlobalePayee: 'Prime Globale Payée',
    Refuse: 'Refusé',
    Abandon: 'Abandon',
    DemandeInformations: "Demande d'informations",
    EnCoursDeValidation: 'En cours de validation',
  },
  statuts_operation: {
    Eligible: 'Éligible',
    AConfirmer: 'Éligibilité à confirmer',
    NonConfirme: 'Eligibilité non confirmée à ce stade',
    Recommandations: 'Recommandations',
    Annule: 'Annulé',
    DossierEnCoursDeValidation: 'Dossier en cours de validation',
    Incomplet: 'Incomplet',
    RemisAuControle: 'Remis au Contrôle',
    PreValide: 'Pré validé',
    BonPourPrime: 'Bon pour Prime',
    NonConforme: 'Non conforme (Qualité)',
    ActionEnCours: 'Action en cours',
    Litige: 'Litige',
    Refuse: 'Refusé',
    Retire: 'Retiré du dépôt',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
    token_error: "Votre lien à expiré ou n'est plus valide",
    impersonate: 'Connecté en tant que {{name}}',
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'Création de votre mot de passe',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description:
      'La création de votre mot de passe a bien été prise en compte.',
    success_action: 'Me connecter',
    submit: 'Valider le mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Changement de mot de passe',
    description: 'Renseignez votre nouveau mot de passe',
    submit: 'Valider le mot de passe',
    success: 'Modification du mot de passe',
    success_description:
      'La modification de votre mot de passe a bien été effectuée.',
    success_action: 'Me connecter',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  error: {
    hero: 'Une erreur est survenue',
    description: "Une erreur est survenue.\nEssayez d'actualiser la page.",
    action: 'Actualiser',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  not_implemented: {
    hero: 'Non implémenté',
  },
  reset_password: {
    hero: 'Réinitialisation du mot de passe',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour à la connexion',
    success: 'Réinitialisation du mot de passe',
    success_description:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.<br>" +
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez votre référent chez Hellio.<br>",
  },
  homepage: {
    hero: 'Accueil',
  },
  ajout_dossier: {
    hero: 'Soumission de dossier',
    success_0: 'Soumission d’un dossier',
    success_1:
      'Votre demande a bien été prise en compte.\nNous vous recontacterons prochainement.',
    home: 'Accueil',
    error: 'Erreur de soumission du dossier',
    error_reason: "Votre dossier n'a pas pu être envoyé :",
    required_fields: "Les champs accompagnés d'un * sont requis.",
  },
  add_dossier_site: {
    isAncien: 'Site existant depuis plus de  2 ans',
    first_power: 'Énergie de chauffage principale',
    electricity: 'Électricité',
    combustible: 'Combustible',
    no_pertinence: 'Non pertinent',
    alert:
      "Dans le cas où le site n'est ni un site ou tertiaire, sélectionner “ Non-pertinent ”",
    alert_2:
      'Si vous n’avez pas d’ordre d’idée de la date d’engagement prévisionnelle, indiquer une date correspondant à la date actuelle + 15 jours',
    surface: 'Surface totale chauffée de la partie réhabilitée',
    dateDebutTravaux: "Date d'engagement prévisionnelle des travaux",
    start_work_after_end_work:
      'Doit être antérieure à la date de fin prévisionnelle des travaux',
    dateFinTravaux: 'Date de fin prévisionnelle des travaux',
    end_work_before_start_work: "Doit être postérieure à la date d'engagement",
    referentCommercialLastname: 'Nom du référent commercial',
    referentCommercialFirstname: 'Prénom du référent commercial',
    referentCommercialEmail: 'Email du référent commercial',
    raisonSocialeBeneficiaire: 'Raison sociale du bénéficiaire',
    nomSite: 'Nom du site chantier',
    adresseSite: 'Adresse du chantier',
    codePostalSite: 'Code postal du chantier',
    villeSite: 'Ville du chantier',
  },
  add_dossier_project: {
    isAncien: 'Bâtiment existant depuis plus de 2 ans',
    nature: 'Nature des travaux',
    usage: 'Usage du site chantier',
    operation: "Type d'opération",
    surfaceVentileeRehabilitee:
      'Surface totale ventilée de la partie réhabilitée (m²)',
    ancienneEnergieChauffage: 'Ancienne énergie de chauffage du bâtiment',
    nouvelleEnergieChauffage: 'Nouvelle énergie de chauffage du bâtiment',
    surfaceChauffee: 'Surface totale chauffée du bâtiment (m²)',
    surfaceChauffeeRehabilitee:
      'Surface totale chauffée de la partie réhabilitée (m²)',
  },
  add_dossier_beneficiary: {
    civilite: 'Civilité',
    lastname: 'Nom du contact bénéficiaire',
    firstname: 'Prénom du contact bénéficiaire',
    email: 'Adresse e-mail du contact bénéficiaire',
    telephone: 'Téléphone du contact bénéficiaire',
    aif_model: "Modèle d'incitation financière souhaité",
    aif_model_undefined: 'Non déterminé',
    own_company: "L'entreprise de travaux est votre société",
    descriptionProjet: 'Décrivez-nous votre projet :',
    files: 'Déposez vos fichiers ici',
    files_description:
      'Vous pouvez ajouter jusqu’à 10 fichiers simultanément.\nSi vous voulez ajouter plus de 10 fichiers, effectuez le dépôt en plusieurs fois.',
    aif_alert:
      'AIF : Accord d’Incitation Financière (contrat)\n' +
      'Remise sur devis : Prime intégrée sur le devis sous forme de remise',
    raisonSocialeETX: "Raison sociale de l'entreprise",
    adresseETX: "Adresse de l'entreprise",
    codePostalETX: "Code postal de l'entreprise",
    villeETX: "Ville de l'entreprise",
    telephoneETX: "Téléphone de l'entreprise",
    emailETX: "E-mail de l'entreprise",
    policies_0:
      "J'accepte d'être recontacté par Hellio ou l'un de ses partenaires.\nPour plus d'informations, je peux consulter la ",
    policies_1: 'Politique de confidentialité de Hellio.',
  },
  dossiers: {
    hero: 'Suivi de dossier',
    filter: 'Filtres',
    referent: 'Référent commercial',
    keyword: 'Référence chantier, adresse des travaux...',
    filter_statuts: 'Filtrer par statuts',
    filter_referent: 'Filtrer par référent commercial',
    export: 'Exporter les données (.csv)',
    reset: 'Réinitialisation',
  },
  dossier_table: {
    no_data: 'Aucune donnée disponible',
    beneficiaire_raisonSociale: 'Raison sociale du bénéficiaire',
    site_nom: 'Nom du site chantier',
    site_adresse: 'Adresse des travaux',
    dateModification: 'Date de mise à jour',
    chantier_dateFinTravaux: 'Date de fin des travaux',
    chantier_reference: 'Référence chantier',
    chantier_montant: 'Montant des primes',
    statut: 'Statut',
    referentCommercial: 'Référent commercial',
    operation_libelle: 'Opération',
    operation_fost: 'Fiche d’opération standardisée',
    operation_montantPrime: 'Montant prime d’opération',
    details: 'Voir détail',
    user: "Raison sociale de l'installateur",
  },
  faq: {
    title: 'FAQ',
    hero_0: 'Foire aux',
    hero_1: 'questions',
  },
  mon_compte: {
    hero: 'Mon compte',
    lastname: 'Nom',
    firstname: 'Prénom',
    email: 'E-mail',
    deconnexion: 'Se déconnecter',
  },
  calculer_une_prime: {
    hero: 'Calculer une prime',
    subtitle:
      'Calculez la prime CEE Hellio associée à votre opération en quelques clics.',
    tab: {
      calculateur: {
        label: 'Calculateur de prime',
        alert_message: `Les champs accompagnés d'un * sont requis.`,
      },
      historique: {
        label: 'Historique de mes simulations',
        export: 'Exporter mes simulations (.csv)',
        table: {
          date: 'Date',
          name: 'Nom de la simulation',
          fost: {
            value: 'Type de bâtiment',
            label: "Libellé de l'opération",
          },
          repartitionPrime: 'Répartition Bénéficiaire / Professionnel',
          volume: 'Volume CEE',
          prime: 'Montant prime globale',
          montantPrimeBenef: 'Montant prime Bénéficiaire',
          montantPrimePro: 'Montant prime Professionnel',
        },
      },
    },
    description:
      'Calculez la prime CEE Hellio associée à votre opération ' +
      'en quelques clics.',
    error_get_data: 'Impossible de récupérer les informations pour le moment :',
    error_simulation: "Impossible d'exécuter la simulation :",
    secteur: 'Secteur',
    date: 'Date',
    numero_fost: "Type d'opération (FOST)",
    ville: 'Ville',
    type_de_batiment: 'Type de bâtiment',
    champs_requis: {
      info_champs_requis: "Les champs accompagnés d'un * sont requis.",
      codePostalVille: 'Code postal',
      code_postal_ville_please_select: 'Sélectionnez un code postal.',
      departement: 'Département',
      departement_please_select: 'Sélectionnez un département.',
      departement_search: 'Numéro ou nom du département…',
      departement_zc: 'Département (ZC)',
      zone_climatique: 'Zone climatique',
      code_postal_ville_search: 'Recherchez par code postal ou par ville…',
      generic_please_select: 'Veuillez saisir le champ {{name}}.',
      generic_placeholder: 'Saisissez {{name}}…',
    },
    operation: "Sélectionnez l'opération correspondant à votre demande",
    operation_placeholder: 'Choisissez parmi la liste…',
    operation_please_select: 'Veuillez sélectionner une opération.',
    operation_date: "Date prévisionnelle de l'opération",
    operation_date_please_select: 'Veuillez sélectionner une date.',
    entrez_code_postal: 'Entrez un code postal',
    batiment: {
      question: "De quel type de bâtiment s'agit-il ?",
      please_select: 'Veuillez sélectionner un secteur.',
      agricole: 'Bâtiment agricole',
      industriel: 'Bâtiment industriel',
      tertiaire: 'Bâtiment tertiaire',
      residentiel: 'Bâtiment résidentiel',
    },
    criteres_eligibilite: "Critères d'éligibilité",
    calculer_ma_prime: 'Calculer ma prime ',
    resultat: {
      votre_situation: 'Votre situation',
      reinitialiser: 'Réinitialiser',
      code_postal: 'Code postal',
      montant_de_la_prime: 'Montant de la prime',
      montant_de_la_prime_description:
        "Le montant de prime estimé indiqué ici est calculé sur la base des paramètres que vous avez indiqué dans le calcul, valable au jour du calcul. Ce montant est conditionné au respect des critères d'éligibilité CEE et est susceptible d'évoluer en fonction des travaux effectivement réalisés, de la date effective d’engagement des travaux, des éventuelles évolutions réglementaires, et de l'évolution des paramètres de chantier déclarés à ce stade.",
      volume_de_CEE_reel: 'Volume de CEE réel',
      prime_globale: 'Prime globale',
      prime_pro: 'Prime professionnel',
      prime_beneficiaire: 'Prime Bénéficiaire',
      prime_professionnel: 'Prime Professionnel',
      repartition_beneficiaire_pro: 'Répartition Bénéficiaire / Professionnel',
      repartition_summary:
        'Je reverse {{pourcentage}} de la prime au Bénéficiaire.',
      montant_max_prime:
        'Le montant maximum de la Prime Professionnel ne peut dépasser {{max}} %.',
      montant_prime_rappel_warning:
        'Nous vous rappelons de faire valider' +
        ' votre devis' +
        ' avec remise par nos équipes spécialisées avant de le faire signer à vos clients.',
      enregistrer_ma_simulation: 'Sauvegarder ma simulation',
      precedent: 'Précédent',
      soumettre_un_dossier: 'Soumettre un dossier',
      enregistrer_une_simulation: 'Sauvegarder une simulation',
      nom_de_la_simulation: 'Nom de la simulation',
      veuillez_nommer_simulation: 'Veuillez nommer votre simulation',
      nom_obligatoire: 'Le nom de la simulation est obligatoire',
      nom_placeholder: 'Nom de la simulation',
      valider: 'Valider',
      confirmation_enregistrement: {
        sauvegarde_de_simulation: 'Sauvegarde de la simulation',
        simulation_bien_enregistree: 'Votre simulation a bien été enregistrée',
        simulation_probleme_enregistrement:
          'Un problème est survenu lors de l’enregistrement de votre simulation',
        acceder_historique_simulation: "Accéder à l'historique des simulations",
      },
      error: {
        montant_prime_non_calculable:
          "Le montant de la prime n'est pas calculable en l'état.",
        information_referent:
          'Pour ce chiffrage spécifique, merci de contacter votre référent Hellio.',
      },
      revenir_a_la_calculette: 'Revenir à la calculette',
    },
  },
  dashboard: {
    hero: 'Tableau de bord',
    hero_print: 'Hellio_Tableau_de_bord_{{date}}',
    export_print: 'Exporter',
    dossiers: {
      title: 'Dossiers en cours selon leur statut',
      vue_operations: 'Vue par opérations',
      vue_chantiers: 'Vue par chantiers',
      etudes_en_cours: 'Études en cours',
      offres_emises: 'Offres émises',
      chantiers_en_cours: 'Chantiers en cours',
      documents_en_attente: 'Documents en attente',
      dossiers_en_cours: 'Dossiers en cours de validation',
      en_attente_bureau: 'En attente de passage Bureau de contrôle',
      dossiers_incomplets: 'Dossiers incomplets',
      dossiers_valides: 'Dossiers validés',
    },
    operationSecteur: {
      title: 'Répartition des opérations par secteur',
    },
    volumetrie: {
      label: 'Volumétrie CEE générée',
      mwhc: 'MWh cumac',
    },
    chantiers: {
      title: 'Chantiers selon la date prévisionnelle de fin de travaux',
      chantiers_mois: 'Chantiers par mois',
      chantiers_trimestre: 'Chantiers par trimestre',
      mention_chantiers: 'Chantiers à démarrer ou en cours',
    },
    montants_primes: {
      validee: 'Montant total des primes validées',
      annee_en_cours: "Montant des primes validées sur l'année en cours",
      annee_precedente: "Montant des primes validées sur l'année précédente",
    },
    top_operations: {
      title: 'Opérations les plus valorisées',
    },
  },
  dossier: {
    hero: 'Vue détaillée du dossier',
    history_title: 'Historique des statuts',
    reference_chantier: 'Référence chantier',
    reference_dossier: 'Référence du dossier',
    statut_dossier: 'Statut du dossier',
    referent_commercial: 'Référent commercial',
    infos_chantier: 'Informations concernant le chantier',
    coordonnees_beneficiaire: 'Coordonnées du Bénéficiaire',
    raison_sociale: 'Raison sociale',
    siren: 'Numéro de SIREN',
    adresse_siege: 'Adresse du siège',
    nom_beneficiaire: 'Nom du bénéficiaire',
    prenom_beneficiaire: 'Prénom du bénéficiaire',
    email_beneficiaire: 'Email du bénéficiaire',
    telephone_beneficiaire: 'Téléphone du bénéficiaire',
    chantier: 'Chantier',
    nom_site: 'Nom du site',
    adresse_chantier: 'Adresse du chantier',
    raison_sociale_installateur: 'Raison sociale de l’installateur',
    siret_installateur: 'SIRET de l’installateur',
    montant_chantier: 'Montant du chantier',
    date_engagement_chantier: "Date d'engagement avec Hellio",
    modele_incitation: 'Choix du modèle d’incitation financière',
    operations: 'Opérations',
    messagerie: 'Messagerie',
    info_operations: 'Informations concernant les opérations',
    libelle_operation: "Libellé de l'opération",
    fiche_operation_standardisee: "Fiche d'opération standardisée",
    fost: 'FOST',
    criteres_eligibilite: "Critères d'éligibilité",
    mentions_facture: 'Mentions à faire figurer sur le devis / facture',
    montant_prime_operation: "Montant prime d'opération",
    pieces_dossier: 'Pièces du dossier',
    statut: 'Statut',
    referent_commercial_indefini: 'Indéfini',
  },

  news: {
    title: 'Actualités',
    read: "Lire l'article",
    loading: 'En cours de chargement. Veuillez patienter...',
    error:
      "Une erreur c'est produite lors du chargement des articles. Veuillez réessayer plus tard!",
  },
  contact: {
    title: 'Votre référent Hellio',
    no_contact: 'Aucun référent commercial n’est rattaché à votre compte.',
  },
  notification: {
    title: 'Notifications',
    no_notification: 'Vous n’avez actuellement aucune notification.',
    mark_as_read: 'Tout marquer comme lu',
    message: {
      DossierEnCoursDeValidation:
        'Le chantier {{dossier.chantier.reference}} – {{dossier.beneficiaire.raisonSociale}} est en cours de validation.',
      DossierValide:
        'Le chantier {{dossier.chantier.reference}} – {{dossier.beneficiaire.raisonSociale}} est passé en "Bon pour Prime".',
      ChantierEnAttenteDeControle:
        'Le chantier {{dossier.chantier.reference}} – {{dossier.beneficiaire.raisonSociale}} est passé en "En attente de contrôle".',
      FinChantierJMoins30:
        "La date prévisionnelle du chantier {{dossier.chantier.reference}} – {{dossier.beneficiaire.raisonSociale}} est proche, merci d'envoyer les dernières pièces du dossier CEE.",
      FinChantierJPlus45:
        "La date prévisionnelle du chantier {{dossier.chantier.reference}} – {{dossier.beneficiaire.raisonSociale}} est dépassée, merci d'envoyer les dernières pièces du dossier CEE.",
      FichierOperation:
        '{{dossier.proprietaire.firstname}} {{dossier.proprietaire.lastname}} a déposé une pièce jointe sur le chantier {{dossier.chantier.reference}} – {{dossier.beneficiaire.raisonSociale}}.',
    },
  },
  attachments: {
    ajouter_fichier: 'Ajouter un fichier',
    deposez_fichiers: 'ou déposez vos fichiers ici',
    error_type: "Le type de fichier {{type}} n'est pas autorisé.",
    error_size: 'La taille des fichiers est limitée à {{size}} Mo.',
    download_error: 'Erreur de téléchargement',
    download_error_description:
      "Le fichier {{file}} n'a pas pu être téléchargé :",
    delete_error: 'Erreur de suppression',
    delete_error_description: "Le fichier {{file}} n'a pas pu être supprimé :",
    upload_error: 'Erreur de chargement',
    upload_error_description: "Le fichier {{file}} n'a pas pu être ajouté :",
  },
  documents: {
    no_document: 'Aucun document.',
  },
};
